module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


if (hasNav === undefined) var hasNav = true;
;
__p += '\n\n<section class="comp-content-header">\n  <div class="container">\n    <div class="comp-content-header-wrapper">\n      <h3 class="comp-content-header-title">\n        About Us\n      </h3>\n      <div class="comp-content-header-body pc">\n        <div class="comp-content-header-path">\n          <a href="#">Home</a>\n          |\n          <a href="#">About Us</a>\n          |\n          <a href="#">Who Are We</a>\n        </div>\n        ';
 if (hasNav) { ;
__p += '\n          <div class="comp-content-header-nav">\n            <a class="comp-content-header-link active" href="#">\n              Who Are We\n            </a>\n            <a class="comp-content-header-link" href="#">\n              Our Culture\n            </a>\n          </div>\n        ';
 } else { ;
__p += '\n          <div class="comp-content-header-desc">\n            <p>\n              Premium quality human cuticle hair supplier\n            </p>\n          </div>\n        ';
 } ;
__p += '\n      </div>\n      <div class="comp-content-header-body mobile">\n        <div class="comp-content-header-select">\n          <p>1 - Who Are We</p>\n          <ul>\n            <li><a href="">1 - Who Are We</a></li>\n            <li><a href="">2 - Who Are We</a></li>\n            <li><a href="">3 - Who Are We</a></li>\n            <li><a href="">4 - Who Are We</a></li>\n          </ul>\n          <i class="iconfont icon-arrow-bottom"></i>\n        </div>\n      </div>\n    </div>\n  </div>\n</section>';

}
return __p
}