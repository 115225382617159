import './index.scss'

let $selectEl = $('.comp-content-header-select')

$selectEl.on('click', function () {
  $(this).toggleClass('active')
})

$(document).on('click', function (e) {
  if ($(e.target).closest($selectEl).length === 0) {
    $selectEl.removeClass('active')
  }
})