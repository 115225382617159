import './index.scss'

import device from 'current-device'

/**
 *  scroll 效果
 **/
!(function () {
  let $headerEl = $('.comp-header')
  let $scrollBefore =$('.comp-header-nav-pc')
  
  $(window).on('scroll', function () {
    let scrollY = window.pageYOffset
    
    if (device.mobile()) {
      $headerEl.removeClass('scroll')
    }
    else if (scrollY === 0) {
      $headerEl.removeClass('scroll')
      $scrollBefore.removeClass('scroll-before')
    }
    else {
      $headerEl.addClass('scroll')
      $scrollBefore.addClass('scroll-before')
    }
  })
})()

/**
 *  pc 端搜索框
 **/
!(function () {
  let $searchEl = $('.comp-header-search-pc')
  let $openAndSubmitBtn = $('.comp-header-search-pc-submit')
  
  /**
   *  若没有显示输入框则打开输入框
   *  若已显示输入框就提交表单
   **/
  $openAndSubmitBtn.on('click', function (e) {
    let isSubmit = $searchEl.hasClass('active')
    let isOpen = !isSubmit
    
    /** 若为提交行为，则继续提交 **/
    if (isSubmit) return
    
    e.preventDefault()
    $searchEl.addClass('active')
  })
  
  /**
   *  点击页面其他位置就关闭搜索框
   **/
  $(document).on('click', function (e) {
    let $target = $(e.target)
    let isOuter = $target.closest($searchEl).length === 0
    
    isOuter && $searchEl.removeClass('active')
  })
})()

/**
 *  mobile 搜索框
 **/
!(function () {
  let $searchEl = $('.comp-header-search-mobile')
  let $panelEl = $('.comp-header-search-mobile-panel')
  let $toggleEl = $('.comp-header-search-mobile-toggle')
  let $closeEl = $('.comp-header-search-mobile-close')
  
  $toggleEl.on('click', function () {
    $searchEl.toggleClass('active')
  })
  $closeEl.on('click', function () {
    $searchEl.removeClass('active')
  })
})()

/**
 *  汉堡包按钮
 *  移动端导航
 **/
!(function () {
  let $hamburgerEl = $('.comp-header-hamburger')
  let $navEl = $('.comp-header-nav-mobile')
  
  $hamburgerEl.on('click', function () {
    $navEl.toggleClass('active')
    $hamburgerEl.children().toggleClass('active')
  })
})()

// !(function(){
//   let $hove = $('.comp-header-nav-pc-item')
//    let $abc = $('.comp-header-nav-pc-item')
//   $hove.on('mouseenter', function () {
//     $(this).children('ul').addClass('activeHove')
//   })
//   $hove.on('mouseleave', function () {
//     $(this).children('ul').removeClass('activeHove')
//   })
// })()