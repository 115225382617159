import './index.scss'

$('.comp-footer-gotop').on('click', function () {
  $('html, body').animate({
    scrollTop: 0
  }, 800)
})

// 阻止右键操作
document.oncontextmenu = function(){
  // alert("当前页面不能使用右键");
  return false;
}
