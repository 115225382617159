module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


if (!title) var title = ''
;
__p += '\n\n<h3 class="comp-content-title">\n  ' +
((__t = ( title )) == null ? '' : __t) +
'\n</h3>';

}
return __p
}