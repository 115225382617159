module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


if (!text) var text = ''
if (!theme) var theme = 'brown'
;
__p += '\n<div class="comp-more ' +
((__t = ( theme )) == null ? '' : __t) +
'">\n  <i class="comp-more-icon iconfont icon-plus"></i>\n  ';
 if (text) { ;
__p += '\n    <p class="comp-more-text">\n      ' +
((__t = ( text )) == null ? '' : __t) +
'\n    </p>\n  ';
 } ;
__p += '\n</div>';

}
return __p
}