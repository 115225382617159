module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


if (!key) var key = ''
if (!value) var value = ''
if (!desc) var desc = ''
;
__p += '\n\n<div class="comp-stats">\n  <div class="comp-stats-body">\n    <p class="comp-stats-value">\n      ' +
((__t = ( value )) == null ? '' : __t) +
'\n    </p>\n    <p class="comp-stats-key">\n      ' +
((__t = ( key )) == null ? '' : __t) +
'\n    </p>\n  </div>\n  <p class="comp-stats-desc">\n    ' +
((__t = ( desc )) == null ? '' : __t) +
'\n  </p>\n</div>';

}
return __p
}