module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


if (!title) var title = '';
if (!desc) var desc = '';
if (!icon) var icon = '';
;
__p += '\n\n<div class="comp-after-sales">\n  <h3 class="comp-after-sales-title">\n    ' +
((__t = ( title )) == null ? '' : __t) +
'\n  </h3>\n  <p class="comp-after-sales-desc">\n    ' +
((__t = ( desc )) == null ? '' : __t) +
'\n  </p>\n  <i class="comp-after-sales-icon iconfont ' +
((__t = ( icon )) == null ? '' : __t) +
'"></i>\n</div>';

}
return __p
}